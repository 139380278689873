// Panels

.panel-default {
  background: #f7f7f7;
  border-radius: 4px;
  border: @border-panel-default;
  padding: @padding-panel-default;
  margin: 0 0 19px 0;

  h3 {
    margin: 0 0 8px 0;
  }

  h4 {
    font-weight: normal;
    padding: 0 0 4px 0;
    margin: 0 0 10px 0;

    &.panel-title {
      border-bottom: 1px solid #c2c2c2;
      font-weight: bold;
      time{
        font-weight: normal;
        font-size: 15px;
      }

      a {
        color: @aveda-green;
        &:hover,
        &:active,
        &:focus {
          color: lighten(@aveda-green, 10%);
        }
      }
    }
  }
}

// Panel Sidebar

.panel-sidebar {
  background: #eee;
  border-radius: 9px;
  box-shadow: 1px 1px 1px 1px #c4c4c5, #cccf90 0px 0px 3px inset;
  padding: @padding-panel-sidebar;
  margin: 0 0 20px 0;

  h4 {
    font-weight: normal;
    margin: 0 0 5px 0;
  }

  .panel-title {
    padding: @padding-panel-sidebar-title;
  }
}

// Panel Schedule

.panel-schedule {
  background: #fafafa;
  margin: @margin-panel-schedule;
  padding: @padding-panel-schedule;
  .box-shadow(1px 3px 6px 3px rgba(0,0,0,0.15));

  h4 {
    border-bottom: 0;
    font-size: 16px;
    margin: 4px 0 5px 0;
    padding: 0;
  }
}

.panel-warning {
  background: #fdefef;
  padding: 17px 12px;
  border-radius: 9px;
  border: 1px solid #e34949;
  margin: @margin-panel-warning;

  h5 {
    font-size: 16px;
    margin: 0 0 12px 0;
  }
}

// Panels on account

.panel-account {
  background: #f3f3f3;
  -moz-box-shadow: 3px 6px 5px 0px rgba(0,0,0,0.34);
  -webkit-box-shadow: 3px 6px 5px 0px rgba(0,0,0,0.34);
  box-shadow: 3px 6px 5px 0px rgba(0,0,0,0.34);
  margin: @margin-panel-account;

  hgroup {
    border-bottom: 1px solid #cdcdcd;
    padding: @padding-panel-account-hgroup;
  }
}

.panel-service {
  background: #fbfbfb;
  -webkit-box-shadow: 2px 3px 8px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 2px 3px 8px 0px rgba(0,0,0,0.15);
  box-shadow: 2px 3px 8px 0px rgba(0,0,0,0.15);
}

// Details

.panel-results {
  background: #fafafa;
  border-radius: 9px;
  border: 1px solid #e2e2e2;
  padding: @padding-panel-results;
}

.panel-details {
  background: #fbfbfb;
  -webkit-box-shadow: 1px 6px 7px 0px rgba(0,0,0,0.54);
  -moz-box-shadow: 1px 6px 7px 0px rgba(0,0,0,0.54);
  box-shadow: 1px 6px 7px 0px rgba(0,0,0,0.54);
  border-radius: 10px;
  padding: @padding-panel-details;
  margin: @margin-panel-details;
}

.panel-highlight {
  background: #f7f7f7;
  border: 1px solid #e2e2e2;
  padding: @padding-panel-highlight;
  border-radius: 9px;
}

.panel-pager {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px; 
  display: table;
  font-size: 16px;
  
}

.panel-pager a {
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
}

.panel-pager span {
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
}
