#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;

  .spinner {
    position: relative;
    top: 50%;
    margin-top: -28px;
  }
}
