.select-service-list,
.select-service-list-sub,
.select-list {
  background: #fff;
  .border-bottom-radius(5px);
  border: 1px solid #c2c2c2;
  -moz-box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.43);
  -webkit-box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.43);
  box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.43);
  padding: 0 !important;
  position: absolute;

  li:nth-child(odd) {
    background: #fff;
  }

  li:nth-child(even) {
    background: #f4f4f4;
  }

  li:last-child {
    .border-bottom-radius(5px);
  }

  li {
    cursor: pointer;
    /*  height: 34px; */
    min-height: 30px; /* 34px; */
    line-height: 30px; /* 34px; */
    margin: 0 !important;
    padding: 0 10px !important;
    text-align: left;
    width: 100% !important;
    display: block;

    &:hover,
    &.active {
      background: @select-li-hover-bg;
    }

    i {
      float: right;
      margin: 8px 0 0 0;
    }
  }

  .popover {
    width: @select-popover-width;
  }
}

.select-service-list,
.select-list {
  min-width: 200px;
  z-index: @zindex-select-main-list;
}

.select-service-list-sub {
  min-height: 400px;
  min-width: 240px;
  margin-left: 190px;
  z-index: @zindex-select-sub-list;
}

#mainSelectServiceCategory,
#mainSelectService {
  top: 30px;
}

#mainSelectServiceCategory {
  left: 8px;
}

#mainSelectService {
  //left: 160px;

  i {
    position: absolute;
    right: 0px;

  }

  li {
    position: relative;
    background: #fff;

    span {
      margin-right: 15px;
    }


    &.even {
      background: #f4f4f4;
    }

    &:hover,
    &.active {
      background: #bde2ff;
    }
  }
}

// Fix issues on confirm appointment form
#confirmAppointment {
  .select-service-list-sub {
    width: 290px !important;
  }

  #serviceSelectorCaret {
    left: 90%;
  }
}

// Rituals
.select-rituals-list,
.select-employee-list {
  margin-top: -25px;
}

.select-rituals-list {
  width: 300px !important;
}

