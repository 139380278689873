// Sidebar

.sidebar-sticky {
  top: 405px;
  z-index: 222;
  .sidebar-links {
    background: #dbd9d9;
    border-radius: 8px;
    border: 1px solid #b8b8b8;
    padding: 10px 0;
    width: 100%;
    &:extend(.list-unstyled);
  }

  ul a {
    background: #d4d2d2;
    border-top: 1px solid #c6c6c6;
    color: #333;
    display: block;
    font-size: 15px;
    padding: 8px 0;
    text-indent: 10px;
    width: 100%;
    &.last {
      border-bottom: 1px solid #c6c6c6;
    }
    i {
      font-size: 16px;
      padding: 0 7px 0 0;
    }

    &:hover {
      background: #f2f2f2;
    }
  }
}
