.sprite-fix-vertical-alignment(@sprite) {
  line-height: extract(@sprite, 4)-1;
}

.img-btn {
  &:focus {
    outline: 0;
  }
}

// Time buttons
.btn-schedule {
  .sprite(@time-button);
  .sprite-fix-vertical-alignment(@time-button);
  display: block;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    .sprite(@time-hover-button);
    color: #fff;
    text-decoration: none;
  }
}

.btn-schedule-taken {
  &:extend(.btn-schedule);
  .sprite(@time-na-button);
  display: inline-block !important;
}

// Find Appointments
.find-appointments-btn {
  margin-top: -5px;
  .text-hide() !important;
  .sprite(@find-appointment-button);
  &:hover,
  &:active,
  &:focus,
  &:active {
    .sprite(@find-appointment-hover-button);
  }
}

.find-appointments-btn-large {
  .text-hide() !important;
  margin: 0 0 40px -4px;
  .sprite(@find-appointment-button-large);
  &:hover,
  &:active,
  &:focus,
  &:active {
    .sprite(@find-appointment-button-hover-large);
  }
}

.book-appointment-btn {
  .text-hide() !important;
  margin: 0 0 40px -4px;
  .sprite(@book-appointment-button);
  &:hover,
  &:active,
  &:focus,
  &:active {
    .sprite(@book-appointment-hover-button);
  }
}

.rebook-appointment-btn {
  margin-top: 5px;
  margin-left: -5px;
  .text-hide() !important;
  .sprite(@rebook-appointment-button);
  &:hover,
  &:active,
  &:focus,
  &:active {
    .sprite(@rebook-appointment-hover-button);
  }
}

.update-contact-info-btn {
  .text-hide() !important;
  .sprite(@update-contact-info);
  margin: 0 0 0 -4px;
  &:hover,
  &:active,
  &:focus,
  &:active {
    .sprite(@update-contact-info-hover);
  }
}

// Sign in button
.sign-in-btn {
  .text-hide() !important;
  .sprite(@sign-in-button);
  margin: 0 0 0 -4px;
  &:hover,
  &:active,
  &:focus,
  &:active {
    .sprite(@sign-in-hover-button);
  }

  &:focus {
    outline: 0;
  }
}

.signup-button {
  background: #9FA617;
  color: #FFFFFF;
  margin: 10px 30px;
  float: right;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
}



// Schedule button
time {
  .btn-schedule {
    color: #fff !important;
    display: inline-block;
    &:hover,
    &:active,
    &:focus {
      color: #fff !important;
      display: inline-block;
    }
  }
}

// link boxes
.link-box {
  min-height: 34px;
  background: #fff;
  display: table;
  margin: 8px 0 10px 0;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.15);
  box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.15);
  padding: 0 10px;
  color: @link-box-green;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  i, span {
    padding: 5px 0 4px 0;
  }
  i {
    display: table-cell;
    font-size: 18px;
    width: 12%;
  }
  span {
    display: table-cell;
    vertical-align: top;
  }
  &:hover {
    background: #fefefe;
    color: @dim-text;
  }
}
