// APP STYLE VARIABLES
@font-family:  'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-size-base: 14px;
@dim-text: #777;

// Bootstrap stuff
@gray-base: #333;
@gray-lighter: #eee;
@brand-primary: darken(#A5AE26, 20.5%);
@brand-success: #5cb85c;
@brand-info: #5bc0de;
@brand-warning: #f0ad4e;
@brand-danger: #d9534f;
@caret-width-base: 6px;

@grid-gutter-width: 16px;
@input-color: @gray-base;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-base: @font-family-sans-serif;

@font-size-base: 14px;
@font-size-large: ceil((@font-size-base * 1.25)); // ~18px
@font-size-small: ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1: floor((@font-size-base * 2.6)); // ~36px
@font-size-h2: floor((@font-size-base * 2.15)); // ~30px
@font-size-h3: ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4: ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5: @font-size-base;
@font-size-h6: ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed: floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family: inherit;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: inherit;

//== Tooltips
//** Tooltip max width
@tooltip-max-width: 200px;
//** Tooltip text color
@tooltip-color: #fff;
//** Tooltip background color
@tooltip-bg:  #000;
@tooltip-opacity: .9;

//** Tooltip arrow width
@tooltip-arrow-width: 5px;
//** Tooltip arrow color
@tooltip-arrow-color: @tooltip-bg;


//== Popovers
//** Popover body background color
@popover-bg: #fff;
//** Popover maximum width
@popover-max-width: 276px;
//** Popover border color
@popover-border-color:  rgba(0,0,0,.2);
//** Popover fallback border color
@popover-fallback-border-color: #ccc;

//** Popover title background color
@popover-title-bg:  darken(@popover-bg, 1%);

//** Popover arrow width
@popover-arrow-width: 10px;
//** Popover arrow color
@popover-arrow-color: @popover-bg;

//** Popover outer arrow width
@popover-arrow-outer-width: (@popover-arrow-width + 1);
//** Popover outer arrow color
@popover-arrow-outer-color: fadein(@popover-border-color, 5%);
//** Popover outer arrow fallback color
@popover-arrow-outer-fallback-color: darken(@popover-fallback-border-color, 20%);

// Additional Popover variables
@popover-content-color: lighten(@gray-base, 10%);
@popover-content-size: 12px;

//== Modals
//
//##

//** Padding applied to the modal body
@modal-inner-padding: 0px;

//** Padding applied to the modal title
@modal-title-padding: 0px;
//** Modal title line-height
@modal-title-line-height: @line-height-base;

//** Background color of modal content area
@modal-content-bg: #fff;

//** Modal content border color
@modal-content-border-color:  rgba(0,0,0,.2);
//** Modal content border color **for IE8**
@modal-content-fallback-border-color: #999;

//** Modal backdrop background color
@modal-backdrop-bg: #000;
//** Modal backdrop opacity
@modal-backdrop-opacity: .5;
//** Modal header border color
@modal-header-border-color: #e5e5e5;
//** Modal footer border color
@modal-footer-border-color: @modal-header-border-color;

@modal-lg:  900px;
@modal-md:  448px;
@modal-sm:  300px;

// More modal variables
// Not from bootstrap

@modal-dialog-bg: #fff;
@modal-dialog-border-radius: 7px;
@modal-title-color: #fff;
@modal-content-padding: 0 30px;
@modal-body-padding: 20px 0;

// Select popover width
@select-popover-width: 280px;
@select-li-hover-bg: #bde2ff;

// Z-index
@zindex-popover: 1060;
@zindex-tooltip: 1070;
@zindex-modal-background: 1040;
@zindex-modal: 1050;
@zindex-close-modal: 1060;
@zindex-iframe: 1000;
@zindex-search-dropdown: @zindex-iframe + 1;
@zindex-select-main-list: @zindex-search-dropdown + 1;
@zindex-select-sub-list: @zindex-select-main-list + 2;

// Links
@green: #a5ac1e;
@dark-green: #4f6f19;
@light-green: lighten(@dark-green, 10%);
@aveda-green: #7d7e14;
@link-box-green: #9fa617;

@link-color: @dark-green;
@link-hover-color: @light-green;
@link-hover-decoration: none;

// Fonts

// Load fonts from this directory.
@icon-font-path: '/assets/fonts/app/';
// File name for all font files.
@icon-font-name: 'aveda-booking';
// Element ID within SVG icon file.
@icon-font-svg-id: 'aveda-booking';

// Layout
@container-width: 1040px;

// Panels

// panel-default
@border-panel-default: 1px solid #b5b5b5;
@padding-panel-default: 15px 12px;

// panel-sidebar
@padding-panel-sidebar: 12px;
@padding-panel-sidebar-title: 8px 0 2px 0;

// panel-schedule

@padding-panel-schedule: 12px 10px;
@margin-panel-schedule: 0 0 20px 0;

// panel-warning
// used on confirmation
@margin-panel-warning: 15px 0 20px 0;

// panel-account
@margin-panel-account: 0 0 30px 0;
@padding-panel-account-hgroup: 18px 5px 5px 8px;

// panel-results
@padding-panel-results: 8px 15px;

// panel-details
// Used on Details page
@padding-panel-details: 0 12px 30px 12px;
@margin-panel-details: 30px 0 30px 0;

// panel-highlight
// Used on details page for the business hours
@padding-panel-highlight: 10px 14px;

// Sections

// Map
@map-height: 600px;
@sidebar-width: 400px;

// Margins and Paddings
@service_padding: 10px;

// Details Sidebar

// Sticky Sidebar
@sticky_sidebar_width: 202px;
@sticky_sidebar_top_padding: 30px;
@sticky_sidebar_margin: 0 25px 0 0;

// Details page

// Account

@filter_appointments_form_margin: 20px 0 30px 0;
@filter_appointments_form_input_height: 29px;
@filter_appointments_form_input_padding: 4px 12px;
@filter_appointments_name_input_width: 260px;
@filter_appointments_name_input_margin: 0 14px 0 0;
@filter_appointments_date_input_width: 147px;

// Bootstrap

// Nav pills
//=== Shared nav styles
@nav-link-padding:  6px 15px;
@nav-link-hover-bg: @gray-lighter;

@nav-disabled-link-color: @gray-light;
@nav-disabled-link-hover-color: @gray-light;

@nav-pills-border-radius: @border-radius-base;
@nav-pills-active-link-hover-bg: lighten(@component-active-bg, 20%);
@nav-pills-active-link-hover-color: lighten(@component-active-color, 20%);


// Forms
// Store setup
@store-setup-form-margin: 20px 0;
@store-setup-input-width: 450px;
@store-setup-input-margin: 10px;
@store-setup-label-width: 210px;
@store-setup-btn-margin-top: 10px;

// Service setup
@service-setup-form-margin: 20px 0 40px 0;
@service-setup-input-width: 450px;
@service-setup-input-margin: 10px;
@service-setup-label-width: 210px;
@service-setup-btn-margin-top: 10px;

// Employee details
@employee-detail-btn-margin-top: 10px;
