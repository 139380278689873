.container {
  width: @container-width !important
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.full-height {
  height: 100%;
}

.main-layout {
  height: 100%;
  top: -60px;
  position: relative;
  padding-top: 55px;
}


.pad-top {
  padding-top: 10px;
}
