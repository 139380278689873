#updateStoreLocationByShortName {
  .GeoFencingRow {
    display: flex;
    align-items: center;
    .emptyStubForFencingNotification {
      height: 15px;
    }
  }
  #geoFencingRadius{
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .CheckboxWrapper {
    height: 36px;
    display: flex;
    align-items: center;
    label{
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .checkboxText{
        margin-left: 5px;
      }
      input[type="checkbox"] {
        margin-top: 0px;
      }
    }
  }
  .green{
    color: #3c763d;
  }
  .red{
    color: #6c121b;
  }
}