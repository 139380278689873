// Account pagination

.account-pagination {
  .page-num {
    font-size: 12px;
  }

  .page-controls {
    a {
      #gradient >.vertical(#c7c2c2, #b8b7b7);
      border: 1px solid #acacac;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      padding: 4px 8px;
      margin: 0;
      display: inline-block;
      &:hover {
        #gradient >.vertical(#b8b7b7, #c7c2c2);
      }
    }
    .first-page,
    .last-page {
      border-radius: 8px;
    }
    .first-page {
      margin: 0 10px 0 0;
    }
    .last-page {
      margin: 0 0 0 10px;
    }
    .prev-page,
    .next-page {
      margin: -4px 0 0 -4px;
      &:before {
        height: 16px;
        line-height: 16px;
      }
    }

    .prev-page {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .next-page {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
