// Interaction Cues

.ui-state-disabled {
  cursor: default !important;
}


// Icons

// states and images
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: block;
  font-size: 12px;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
  font-size: 12px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 3px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker-calendar table {
  font-size: 12px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  left: 50%;
  margin-left: -10px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
}

.ui-datepicker .ui-datepicker-title {
  font-size: 14px;
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%;
}

.ui-datepicker table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  margin: 0 0 .4em;
}

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-menu {
  display: block;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 3px 1em 3px .4em;
  cursor: pointer;
  min-height: 0; /* support: IE7 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}

.ui-menu .ui-menu-divider {
  border-width: 1px 0 0 0;
  font-size: 0;
  height: 0;
  line-height: 0;
  margin: 5px 0;
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px;
}

// icon support
.ui-menu-icons {
  position: relative;
}
.ui-menu-icons .ui-menu-item {
  padding-left: 2em;
}

// left-aligned
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0;
}

// right-aligned
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0;
}

// Component containers
.ui-widget {
  font-size: 1.1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget-content {
  background: #fff;
  border: 1px solid #aaa;
  color: #222;
}

.ui-widget-content a {
  color: #222;
}

.ui-widget-header {
  border: 1px solid #ccc;
  color: #fff;
  #gradient >.vertical(#abb42a, #c4cf2b);
  font-weight: bold;
}
.ui-widget-header a {
  color: #fff;
}

// Interaction states
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #d3d3d3;
  background: #e6e6e6;
  font-weight: normal;
  color: #555;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #bfc83f;
  border: 1px solid #a8af24;
  color: #fff;
  font-weight: normal;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  color: #fff;
  text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #a8af24;
  background: #bfc83f;
  font-weight: normal;
  color: #fff !important;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #fff !important;
  text-decoration: none;
}

// Interaction Cues
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #fcefa1;
  color: #889008;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #363636;
}

.ui-state-highlight.ui-state-hover {
  color: #fff;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter:Alpha(Opacity=35); /* support: IE8 */
  background-image: none;
}
.ui-state-disabled .ui-icon {
  filter:Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

// Icons

// states and images

.ui-icon {
  width: 18px;
  height: 16px;
}

.ui-icon,
.ui-widget-content .ui-icon,
.ui-state-default .ui-icon
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-state-default .ui-icon,
.ui-state-active .ui-icon {
  &:extend(.img-btn);
}

// positioning
.ui-icon-circle-triangle-e {
  .sprite-position(@datepicker-next);
}

.ui-icon-circle-triangle-w {
  .sprite-position(@datepicker-prev);
}

// Misc visuals

// Corner radius
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 6px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 6px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 6px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 6px;
}

.ui-datepicker-prev.ui-state-hover,
.ui-datepicker-next.ui-state-hover {
  background: none !important;
  border: 0;
}
