// Forms

label {
  color: @dim-text;
  font-weight: normal;
}

// Placeholder
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #888;
}

// Form fields with icons
.confirmation-service-time-group,
.service-date-group {
  margin: 0;
  padding: 0;
}

#confirmationDateIcon {
  color: @gray-base;
  cursor: pointer;
  font-size: 20px;
  left: 87%;
  position: relative;
  top: -31px;
}

#serviceDateIcon {
  color: @gray-base;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  right: 2%;
  top: -27px;
}

#confirmationAvedaServiceRitual {
  font-size: 13px;
}

#confirmationServiceIcon,
#confirmationAvedaServiceRitualIcon {
  position: relative;
  top: -28px;
  left: 89%;
}

#confirmationProviderIcon {
  left: 89%;
  position: relative;
  top: -27px;
}

#serviceSelectorCaret {
  position: relative;
  left: 185px;
  top: -28px;
}

.select-service-area {
  .has-error {
    #serviceSelectorCaret {
      top: -50px;
    }
  }
}

#confirmationTimeIcon {
  position: relative;
  top: -27px;
  left: 45%;
}

fieldset.form-inline {
  margin-bottom: 20px;
}

.last-fieldset {
  margin-bottom: 25px;
}

.hidden-block {
  display: none;
}

// Form errors
.help-block,
.input-error {
  display: block;
  margin-top: 3px;
  margin-bottom: 0;
}

.inline-error {
  display: inline;
  float: right;
  position: relative;
  color: @state-danger-text;
}

.input-error {
  color: @state-danger-text;
}

.remove-link {
  color:#999 !important;
  line-height: 32px;
  vertical-align: bottom;
}

.service-lines .row:first-child .remove-link {
  display: none;
}

