// Import the fonts
@font-face {
  font-family: 'aveda-booking';
  src: url('@{icon-font-path}@{icon-font-name}.eot');
  src: url('@{icon-font-path}@{icon-font-name}.eot?#iefix') format('embedded-opentype'),
  url('@{icon-font-path}@{icon-font-name}.woff') format('woff'),
  url('@{icon-font-path}@{icon-font-name}.ttf') format('truetype'),
  url('@{icon-font-path}@{icon-font-name}.svg#@{icon-font-svg-id}') format('svg');
}


[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "aveda-booking";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-search:before { content: '\e800'; } /* '' */
.icon-star-half-alt:before { content: '\e801'; } /* '' */
.icon-star:before { content: '\e802'; } /* '' */
.icon-star-empty:before { content: '\e803'; } /* '' */
.icon-info-circled:before { content: '\e804'; } /* '' */
.icon-attention-circled:before { content: '\e805'; } /* '' */
.icon-th-large:before { content: '\e806'; } /* '' */
.icon-th-list:before { content: '\e807'; } /* '' */
.icon-align-justify:before { content: '\e808'; } /* '' */
.icon-right-open:before { content: '\e809'; } /* '' */
.icon-left-big:before { content: '\e80a'; } /* '' */
.icon-right-big:before { content: '\e80b'; } /* '' */
.icon-mail:before { content: '\e80c'; } /* '' */
.icon-comment:before { content: '\e80d'; } /* '' */
.icon-location:before { content: '\e80e'; } /* '' */
.icon-direction:before { content: '\e80f'; } /* '' */
.icon-play:before { content: '\e810'; } /* '' */
.icon-login:before { content: '\e811'; } /* '' */
.icon-logout:before { content: '\e812'; } /* '' */
.icon-edit:before { content: '\e813'; } /* '' */
.icon-cancel:before { content: '\e814'; } /* '' */
.icon-menu:before { content: '\e815'; } /* '' */
.icon-chat-empty:before { content: '\e816'; } /* '' */
.icon-user:before { content: '\e817'; } /* '' */
.icon-map:before { content: '\e818'; } /* '' */
.icon-calendar:before { content: '\e819'; } /* '' */
.icon-calendar-empty:before { content: '\e81a'; } /* '' */
.icon-cw:before { content: '\e81b'; } /* '' */
.icon-ccw:before { content: '\e81c'; } /* '' */
.icon-arrows-cw:before { content: '\e81d'; } /* '' */
.icon-phone:before { content: '\e81e'; } /* '' */
.icon-doc:before { content: '\e81f'; } /* '' */

