// Image borders
.img-border {
  border: 1px solid #b1b1b1;
}

// Attributes
.location-services {
  .text-hide() !important;
  a {
    display: inline-block !important;
    margin: 0 0 0 -4px;
    padding: 0;
  }

  .em {
    .sprite(@aveda-em);
  }

  .pp {
    .sprite(@aveda-pp);
  }

  .sr {
    .sprite(@aveda-sr);
  }

  .ss {
    .sprite(@aveda-ss);
  }

  .hc {
    .sprite(@aveda-hc);
  }
}
