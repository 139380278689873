#login {

  .error-message {
    text-align: center;
    margin-top: 50px;
  }

  .modal-dialog {
    display: block;
  }

  .modal-body {
    border: 1px solid grey;
    border-top: 0;
  }
}