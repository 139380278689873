.icon-info-circled {
  color: #abb42a;
  &:extend(.icon-info-circled);
  font-size: 17px;
}

.change-view {
  ul,
  ul li,
  a {
    margin: 0;
    padding: 0;
  }
  .icon-location,
  .icon-align-justify,
  .icon-th-large {
    font-size: 34px;
    color: #777;
    &:hover,
    &.active {
      color: #abb42a;
      text-decoration: none;
    }
    &:before {
      margin: 0 0 0 10px;
      padding: 0;
      text-align: right;
    }
  }
}

// Ratings
.star-ratings {
  i {
    color: #aab329;
    text-indent: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    &:before {
      margin: 0 0 0 0;
      padding: 0;
      text-align: left;
    }
  }
}

.star-ratings-large,
.star-ratings {
  display: inline-block;
}

.star-ratings-large {
  .star-ratings {
    font-size: 18px;
    line-height: 18px;
    i {
      &:before {
        width: 0.8em;
      }
    }
  }
}


.star-ratings-small {
  .star-ratings {
    font-size: 14px;
    line-height: 14px;
    i {
      &:before {
        width: 0.7em;
      }
    }
  }
}

.ratings-group {
  display: table;
  &:extend(.clearfix);
}

// Get Directions

.get-directions {
  &:hover,
  &:active,
  &:focus {
    color: @light-green;
    text-decoration: none !important;
  }
}

// Font Awesome
.fa {
  color: @gray-base;
  &:hover,
  &:active {
    color: @gray-base;
  }
}

// For forms
.caret {
  cursor: pointer;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  color: @gray-base !important;
  border-top:   @caret-width-base dashed;
  border-top:   @caret-width-base solid ~"\9"; // IE8
  border-right: @caret-width-base solid transparent;
  border-left:  @caret-width-base solid transparent;
  &:hover,
  &:active {
    color: @gray-base !important;
  }
}

.close {
  font-weight: bold;
  font-size: 20px;
  padding: 2px 2px 0 0;
  display: inline-block;
}

.heading-icon {
  i {
    font-size: 22px;
    color: @aveda-green;
  }
}

.input-group-addon {
  .fa {
    font-weight: normal !important;
  }
}
