@import (inline) "../../../bower_components/cropper/dist/cropper.min.css";
@import "../bootstrap/bootstrap";

// Variables
@import '../general/variables';

// Sprites
@import '../../../dist/temp/less/sprites-img-btn';
@import '../../../dist/temp/less/sprites-img-icon';

// Mixins
@import "../general/components/mixins";

// Utilities
@import "../general/components/utilities";

// Other Components
@import "../general/components/buttons";
@import "../general/components/datepicker";
@import "../general/components/dividers";
@import "../general/components/forms";
@import "../general/components/icons";
@import "../general/components/icons-extended";
@import "../general/components/images";
@import "../general/components/layout";
@import "../general/components/loading";
@import "../general/components/modal";
@import "../general/components/pagination";
@import "../general/components/panels";
@import "../general/components/popover";
@import "../general/components/select";
@import "../general/components/sidebar";
@import "../general/components/type";

@import "../general/sections/login";
@import "./store-group-aliases";
@import "./store-manager";

.preview-with-trim{
  cursor: pointer;
  border: 1px solid @brand-info;
}

.modal-title{
  color: black !important;
}