// This shows the content within modal box
.fade.in {
  display: block;
}

.modal-dialog {
  background: @modal-dialog-bg;
  border-radius: @modal-dialog-border-radius;
}

.modal-content-padding {
  padding: @modal-content-padding;
}

.modal-header {
  #gradient >.vertical(#9ca710, #bebf48);
  .border-top-radius(@modal-dialog-border-radius);
  border: 1px solid #9fa617;
  height: 50px;
  line-height: 50px;
  // Close
  a.close {
    #gradient >.vertical(#727272, #9d9d9d);
    border-radius: 12px;
    color: #fff !important;
    font-weight: lighter !important;
    height: 26px;
    line-height: 24px;
    .opacity(1.0);
    padding: 0;
    position: absolute;
    right: -7px;
    text-align: center;
    top: -14px;
    width: 26px;
    z-index: @zindex-close-modal;
    .box-shadow(0 1px 1px rgba(0,0,0,.2));
  }
}

.modal-title {
  color: @modal-title-color;
  height: 50px;
  line-height: 50px;
}

// Fix vertical alignment
// This works becase we only have one modal for sign in
.modal {
  text-align: center;
  &:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  top: 100px;
  vertical-align: top;
}

.modal-body {
  padding: @modal-body-padding;
}
