// Typography

// Headings

h1,
h2,
h3,
h4 {
  color: @gray-base;
  font-weight: normal;
}

p {
  color: @gray-base;
}

// Light color
.dim {
  color: @dim-text;
}

.dark {
  color: @gray-base;
}

// Links
a {
  &:focus {
    outline-offset: 0.5px;
  }
}

form a {
  color: @dark-green !important;
  &:hover {
    color: lighten(@dark-green, 5%) !important;
    text-decoration: none;
  }
}

.bold-link {
  color: @aveda-green !important;
  font-weight: bold !important;
  &:hover {
    color: lighten(@aveda-green, 5%) !important;
    text-decoration: none;
  }
}

// Salon name
h4.salon-name {
  text-align: left;
  a {
    color: #4f6f19;
    text-decoration: none;
    font-weight: normal;
    font-size: 105%;
    &:hover {
      color: #a5ae26;
    }
  }
  margin: 0 0 0 0;
}

// Other headings
h3.title {
  margin: 0 0 15px 0;
}

// Placeholder text

.placeholder-message {
  text-align: center;
  width: 600px;
  margin: 100px auto 0 auto;
  color: @gray-light;
  font-size: 18px;
}
