// The background
.wrapper-bg-dark {
  background: #f3f3f3;
}

.wrapper-bg-light {
  background: #fbfcfc;
}

.body-bg-transparent,
.wrapper-bg-account {
  background: transparent;
}

.body-bg-account {
  background: url('/assets/aveda/white-grey-gradient.png') repeat-x fixed left top;
}

// For the layout
.full-width {
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.display-table {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: top;
}

// Float lists for button schedules
// This works for all including Safari

.float-lists {
  width: 264px;
  &:extend(.pull-right);
  li {
    &:extend(.pull-left);
  }
}

// Margins and paddings
.no-gap {
  margin: 0;
  padding: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.page-space {
  margin-bottom: 180px;
}

// For the iFrame
.frame {
  z-index: @zindex-iframe;
  background: #fff; // could change
  position: relative;
}

.codebox {
  background-color:white;
  padding: 10px;
}

input::-ms-clear {
  display: none;
}
