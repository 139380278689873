.search-divider {
  border-bottom: 1px solid #e4e4e4;
}

.panel-sidebar-divider {
  border-bottom: 1px solid #9b9b9b;
}

.panel-schedule-divider {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
