.c-store-group-aliases {
  position: relative;

  &__notify--push-up {
    position: fixed;
    z-index: 10;
    transition: transform 0.3s;
    height: 70px;
    top: 10px;
    left: 0;
    right: 0;
    transform: translateY(-120%);
    width: 810px;
    margin: 0 auto;
    overflow-y: hidden;
    border-radius: 5px;
    text-align: center;

    -webkit-box-shadow: 1px 1px 3px 0 rgba(148, 148, 148, 1);
    -moz-box-shadow: 1px 1px 3px 0 rgba(148, 148, 148, 1);
    box-shadow: 1px 1px 3px 0 rgba(148, 148, 148, 1);

    &.is-active {
      transform: translateY(0);
    }
  }
}