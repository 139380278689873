/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@img-icon-facebook-round-name: 'img-icon-facebook-round';
@img-icon-facebook-round-x: 0px;
@img-icon-facebook-round-y: 0px;
@img-icon-facebook-round-offset-x: 0px;
@img-icon-facebook-round-offset-y: 0px;
@img-icon-facebook-round-width: 40px;
@img-icon-facebook-round-height: 40px;
@img-icon-facebook-round-total-width: 153px;
@img-icon-facebook-round-total-height: 506px;
@img-icon-facebook-round-image: '../../images/sprites-img-icon.png';
@img-icon-facebook-round: 0px 0px 0px 0px 40px 40px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-facebook-round';
@img-icon-first-available-name: 'img-icon-first-available';
@img-icon-first-available-x: 0px;
@img-icon-first-available-y: 200px;
@img-icon-first-available-offset-x: 0px;
@img-icon-first-available-offset-y: -200px;
@img-icon-first-available-width: 153px;
@img-icon-first-available-height: 153px;
@img-icon-first-available-total-width: 153px;
@img-icon-first-available-total-height: 506px;
@img-icon-first-available-image: '../../images/sprites-img-icon.png';
@img-icon-first-available: 0px 200px 0px -200px 153px 153px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-first-available';
@img-icon-googleplus-round-name: 'img-icon-googleplus-round';
@img-icon-googleplus-round-x: 0px;
@img-icon-googleplus-round-y: 40px;
@img-icon-googleplus-round-offset-x: 0px;
@img-icon-googleplus-round-offset-y: -40px;
@img-icon-googleplus-round-width: 40px;
@img-icon-googleplus-round-height: 40px;
@img-icon-googleplus-round-total-width: 153px;
@img-icon-googleplus-round-total-height: 506px;
@img-icon-googleplus-round-image: '../../images/sprites-img-icon.png';
@img-icon-googleplus-round: 0px 40px 0px -40px 40px 40px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-googleplus-round';
@img-icon-more-providers-name: 'img-icon-more-providers';
@img-icon-more-providers-x: 0px;
@img-icon-more-providers-y: 353px;
@img-icon-more-providers-offset-x: 0px;
@img-icon-more-providers-offset-y: -353px;
@img-icon-more-providers-width: 153px;
@img-icon-more-providers-height: 153px;
@img-icon-more-providers-total-width: 153px;
@img-icon-more-providers-total-height: 506px;
@img-icon-more-providers-image: '../../images/sprites-img-icon.png';
@img-icon-more-providers: 0px 353px 0px -353px 153px 153px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-more-providers';
@img-icon-twitter-round-name: 'img-icon-twitter-round';
@img-icon-twitter-round-x: 0px;
@img-icon-twitter-round-y: 80px;
@img-icon-twitter-round-offset-x: 0px;
@img-icon-twitter-round-offset-y: -80px;
@img-icon-twitter-round-width: 40px;
@img-icon-twitter-round-height: 40px;
@img-icon-twitter-round-total-width: 153px;
@img-icon-twitter-round-total-height: 506px;
@img-icon-twitter-round-image: '../../images/sprites-img-icon.png';
@img-icon-twitter-round: 0px 80px 0px -80px 40px 40px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-twitter-round';
@img-icon-yelp-round-name: 'img-icon-yelp-round';
@img-icon-yelp-round-x: 0px;
@img-icon-yelp-round-y: 120px;
@img-icon-yelp-round-offset-x: 0px;
@img-icon-yelp-round-offset-y: -120px;
@img-icon-yelp-round-width: 40px;
@img-icon-yelp-round-height: 40px;
@img-icon-yelp-round-total-width: 153px;
@img-icon-yelp-round-total-height: 506px;
@img-icon-yelp-round-image: '../../images/sprites-img-icon.png';
@img-icon-yelp-round: 0px 120px 0px -120px 40px 40px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-yelp-round';
@img-icon-youtube-round-name: 'img-icon-youtube-round';
@img-icon-youtube-round-x: 0px;
@img-icon-youtube-round-y: 160px;
@img-icon-youtube-round-offset-x: 0px;
@img-icon-youtube-round-offset-y: -160px;
@img-icon-youtube-round-width: 40px;
@img-icon-youtube-round-height: 40px;
@img-icon-youtube-round-total-width: 153px;
@img-icon-youtube-round-total-height: 506px;
@img-icon-youtube-round-image: '../../images/sprites-img-icon.png';
@img-icon-youtube-round: 0px 160px 0px -160px 40px 40px 153px 506px '../../images/sprites-img-icon.png' 'img-icon-youtube-round';
@spritesheet-width: 153px;
@spritesheet-height: 506px;
@spritesheet-image: '../../images/sprites-img-icon.png';
@spritesheet-sprites: @img-icon-facebook-round @img-icon-first-available @img-icon-googleplus-round @img-icon-more-providers @img-icon-twitter-round @img-icon-yelp-round @img-icon-youtube-round;
@spritesheet: 153px 506px '../../images/sprites-img-icon.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  background-image: e(%('url(%a)', e(@sprite-image)));
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
